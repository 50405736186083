import {useEffect} from 'react';

import {useTranslation} from 'react-i18next';

import {videoFilesAPI} from '@/api';
import {FullScreenLoader, RemoveVideo} from '@/components';
import {VideoFileCard} from '@/components/Card';
import {useHandlingError} from '@/hooks';
import {CardList, InfoTitle, InfoWrapper, VideoFileItem} from '@/pages/LibraryPage';

export const FilesWrapper = () => {
  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();
  const {
    data: videoFiles,
    isSuccess: videoFilesIsSuccess,
    isError: videoFilesIsError,
    isLoading: videoFilesIsLoading  } = videoFilesAPI.useGetAllVideoFilesQuery();


  useEffect(() => {
    catchError(videoFilesIsError);
  }, [videoFilesIsError]);

  return (
    <>
      <CardList>
        {videoFilesIsSuccess &&
          videoFiles &&
          videoFiles.map((videoFile) => (
            <VideoFileItem key={videoFile.publicId}>
              <VideoFileCard menu
                             videoFile={videoFile}
              />
            </VideoFileItem>
          ))}
      </CardList>
      {videoFilesIsSuccess && videoFiles.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_v')}</InfoTitle>
        </InfoWrapper>
      )}
      {videoFilesIsLoading && <FullScreenLoader />}
      <RemoveVideo />
    </>
  );
};
