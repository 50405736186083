import {
  ButtonTab,
  InfoBlockPlaylistMaterial,
  Inner,
  Panel,
  TabWrapper,
  TextTab,
  Title,
  VideoWrapper
} from './VideoPage.styled';

import Container from '../../components/Shared/Container/Container';
import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';

import {memo, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';
// eslint-disable-next-line import/named
import YouTube from 'react-youtube';

import {videosAPI} from '@/api';
import {FileVideoPlayer} from '@/components';
import {useActions, useAppSelector, useHandlingError, useMatchMedia} from '@/hooks';
import SearchInVideoInput from "@/components/Shared/SearchInVideoInput/SearchInVideoInput";
import {InfoText} from "@/pages/AdminPage/AdminPage.styled";

const PublicVideoPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [, setParams] = useSearchParams();
  const { catchError } = useHandlingError();
  const isTablet = useMatchMedia('(max-width: 768px)');
  const [tab, setTab] = useState(1);
  const [showInfo, setShowInfo] = useState(false)


  const isAuth = useAppSelector((state) => state.user.isAuth);

  const { showNotification } = useActions();

  const {
    data: video,
    isLoading,
    error: videoError,
  } = videosAPI.useGetMovieByIdQuery({ id: id ?? '' }, { skip: !isAuth });

  useEffect(() => {
    if (!isAuth) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  useEffect(() => {
    catchError(videoError);
  }, [videoError]);

  const height = isTablet ? '300px' : '500px';

  return (
    <Container>
      {isLoading && <FullScreenLoader />}
      {video && (
        <Inner>
          <VideoWrapper>
            {video.source === 'YOUTUBE' && (
              <YouTube
                videoId={video.videoId}
                title={video.title}
                style={{ width: '100%', height }}
                opts={{
                  height,
                  width: '100%',
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                  },
                }}
              />
            )}

            {video.source === 'VK' && (
              <iframe
                title={video.title}
                src={`${video.originLink}&hd=2&autoplay=1`}
                width="100%"
                height={height}
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              ></iframe>
            )}

            {video.source === 'UPLOADED' && <FileVideoPlayer height={height} url={video.originLink} />}
          </VideoWrapper>
          <Title>{video.title}</Title>
          {isAuth && (
            <>
              <div>
                <Panel>
                  <div style={{ flex: 1 }}>
                    {isAuth && <SearchInVideoInput  getSearch={async () => Promise.resolve()}
                                                   disabled={true}
                    />}
                  </div>
                  <TabWrapper onMouseOver={()=>setShowInfo(true)}
                              onMouseOut={()=>setShowInfo(false)}
                              disabled={true}>
                    <ButtonTab disabled={true}
                               theme={`${tab === 1 ? 'white' : 'transparent'}`}
                               onClick={() => setTab(1)}>
                      <TextTab>{t('timecodes', { ns: 'components' })}</TextTab>
                    </ButtonTab>
                    <ButtonTab disabled={true}
                               theme={`${tab === 2 ? 'white' : 'transparent'}`}
                               onClick={() => setTab(2)}>
                      <TextTab>{t('summary', { ns: 'components' })}</TextTab>
                    </ButtonTab>
                    <ButtonTab disabled={true}
                               theme={`${tab === 3 ? 'white' : 'transparent'}`}
                               onClick={() => setTab(3)}>
                      <TextTab>{t('quiz', { ns: 'components' })}</TextTab>
                    </ButtonTab>
                  </TabWrapper>
                  {showInfo &&
                      <InfoBlockPlaylistMaterial>
                          <InfoText>{t('info_public_pl_text', {ns: 'components'})}</InfoText>
                      </InfoBlockPlaylistMaterial>
                  }
                </Panel>
              </div>
            </>
          )}
        </Inner>
      )}
    </Container>
  );
};

export default memo(PublicVideoPage);
