import { api } from "@/api/api";
import { BaseParams, GetList, VideoFile } from "@/types";

const PATH = 'video-files';

export const videoFilesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    createVideoFile: build.mutation<VideoFile, FormData>({
      query: (body) => ({
        url: `${PATH}/`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result) =>
        result
          ? [
            { type: 'video-files', id: result.publicId },
            { type: 'video-files', id: 'LIST' },
          ]
          : [{ type: 'video-files', id: 'LIST' }],
    }),
    getAllVideoFiles: build.query<VideoFile[], { params?: BaseParams } | void>({
      query: () => ({
        url: `${PATH}/`,
        method: 'GET'
      }),

      transformResponse: (data: GetList<VideoFile>) => data.results,

      providesTags: (result) =>
        result
          ? [...result.map(({ publicId: id }) => ({ type: 'video-files' as const, id })), { type: 'video-files', id: 'LIST' }]
          : [{ type: 'video-files', id: 'LIST' }],
    }),
    getOneVideoFiles: build.query<VideoFile, { public_id: string }>({
      query: ({ public_id }) => ({
        url: `${PATH}/${public_id}/`,
        method: 'GET'
      }),

      providesTags: (result, _, { public_id: id }) =>
        result
          ? [{ type: 'video-file' as const, id }]
          : [{ type: 'video-file', id: 'one' }],
    }),

    deleteVideoFile: build.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: `${PATH}/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, { id }) =>
        result
          ? [{ type: 'video-files' as const, id }]
          : [{ type: 'video-files', id: 'LIST' }],
    }),
  })
})

export const {
  useCreateVideoFileMutation,
  useGetAllVideoFilesQuery,
  useGetOneVideoFilesQuery,
} = videoFilesAPI;