import styled from 'styled-components';

import { theme } from '@/styles';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 576px) {
    margin-top: 13vh;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 50px;
    padding-left: 8vw;
    padding-right: 3vw;
  }

  @media (min-width: 1200px) {
    gap: 0;
    //gap: 240px;
    padding-left: 15vw;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
    padding-top: 12px;

  @media (min-width: 768px) {
    padding-left: 40px;
  }

  @media (min-width: 992px) {
    gap: 40px;
    width: 495px;
  }
`;
export const WordGreen = styled.span`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 800;
    line-height: 150%;
    color: ${theme.colors.green.green_light};
    padding-right: 1px;
    padding-left: 5px;

    @media (min-width: 992px) {
        font-size: 21px;
        padding-right: 5px;
        padding-left: 5px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

export const Text = styled.p`
  font-family: ${theme.fonts.nunito};
  font-size: 16px;
  font-weight: 400;
    line-height: 150%;
  color: ${theme.colors.label.label_light};

  @media (min-width: 992px) {
    font-size: 21px;
  }

  @media (min-width: 1400px) {
    font-size: 26px;
  }
`;

export const ImageBack = styled.img`
  position: relative;
    width: 330px;
    height: 233px;
  top: 20%;
  left: 10%;
  margin-bottom: 36%;

  @media (min-width: 576px) {
    left: 35%;
  }

  @media (min-width: 768px) {
    margin-bottom: 30%;
  }

  @media (min-width: 992px) {
    left: -2%;
    top: 0;
  }

  @media (min-width: 1200px) {
    top: 20%;
    left: 60%;
  }

  @media (min-width: 1400px) {
    position: absolute;
    right: 10%;
    margin-bottom: 0;
    margin-top: -45px;
    width: 550px;
    height: 352px;
    left: 58%;
  }
`;

export const ImageFront = styled.img`
    width: 330px;
    height: 233px;
    position: absolute;
    z-index: 4;
    top: 21%;
    right: 13%;

  @media (min-width: 576px) {
    right: 28%;
  }

  @media (min-width: 992px) {
    right: 9%;
  }

  @media (min-width: 1200px) {
    position: absolute;
    z-index: 4;
    top: 30%;
    right: 20%;
  }

  @media (min-width: 1400px) {
    top: 30%;
    right: 12%;
    width: 550px;
    height: 352px;
  }
`;

export const ImageContainer = styled.div`
  @media (min-width: 576px) {
    margin-top: -18%;
  }

  @media (min-width: 768px) {
    margin-top: -10%;
  }

  @media (min-width: 992px) {
    margin-top: -89px;
  }

  @media (min-width: 1200px) {
    margin-top: -10%;
  }
`;
