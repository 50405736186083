import { QuizStyled, Text } from './Quiz.styled';

import { Complete } from '../Complete';
import { Question } from '../Question/Question';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetAllFileQuizzesQuery, useGetVideoAllQuizzesQuery, useGetVideoQuizQuery } from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLo/FullScreenLoader';
import { useAppSelector, useHandlingError } from '@/hooks';

interface QuizProps {
  publicId?: string;
  playlistId?: string;
  goToTime: (time: number | string) => void;
  hash?: string;
  videoPublicId?: string;
  isVideoFile?: boolean;
  playlistType: string;
}

export const Quiz = ({
                       publicId = '',
                       playlistId = '',
                       goToTime,
                       hash,
                       videoPublicId = '',
                       isVideoFile = false,
                       playlistType,
}: QuizProps) => {
  const { t } = useTranslation('loading');

  const shouldFetch = playlistType === 'public';


  const { data, isLoading, error, isError } = useGetVideoQuizQuery(
    { playlistId, publicId },
    { skip: Boolean(hash) || !publicId || !playlistId || isVideoFile || shouldFetch },
  );
  const {
    data: privateQuiz,
    isLoading: isPrivateQuizLoading,
    isError: isPrivateQuizError,
    error: privateQuizError,
  } = useGetVideoAllQuizzesQuery({ playlistId, videoPublicId, hash }, { skip: Boolean(publicId) || isVideoFile || shouldFetch });

  const {
    data: fileQuizz,
    isLoading: isFileQuizzLoading,
    isError: isFileQuizzError,
    error: fileQuizzError,
  } = useGetAllFileQuizzesQuery({ public_id: videoPublicId }, { skip: !isVideoFile || shouldFetch });

  const [activeQuestionIndex, questions, done] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
    state.quiz.done,
  ]);

  const { catchError } = useHandlingError();

  useEffect(() => {
    catchError(error || privateQuizError || fileQuizzError);
  }, [error, privateQuizError, fileQuizzError]);

  if (isPrivateQuizError || isError || isFileQuizzError) {
    return null;
  }

  return (
    <QuizStyled>
      {(isLoading || isPrivateQuizLoading || isFileQuizzLoading) && <FullScreenLoader />}
      {!questions && <Text>{t('load_quiz')}</Text>}
      {(data || privateQuiz || fileQuizz) && !done && (
        <Question {...questions[activeQuestionIndex]} goToTime={goToTime} />
      )}
      {done && <Complete />}
    </QuizStyled>
  );
};
