import { Text, Wrapper } from './Timecodes.styled';

import { convertFromRaw, EditorState } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';

import {useGetAllFullSummaryQuery, useGetDocsQuery, useLazyGetDocsQuery} from '@/api';
import { Button, FullScreenLoader } from '@/components';
import { useActions } from '@/hooks';
// import './styles/customStyles.css';

export const Summary = ({
                          hash,
                          isVideoFile,
                          playlistId,
                          videoPublicId,
                          playlistType
                        }: {
  playlistId?: string;
  hash?: string;
  videoPublicId?: string;
  isVideoFile?: boolean;
  playlistType: string
}) => {
  const { t } = useTranslation('loading');

  const shouldFetch = playlistType === 'public';


  const { data, isLoading } = useGetDocsQuery(
    { playlistId: playlistId ?? '', videoPublicId: videoPublicId ?? '', hash },
    {
      skip: isVideoFile || shouldFetch
    },
  );

  const { data: fileFullSummary, isLoading: isFileSummaryLoading } = useGetAllFullSummaryQuery(
    { public_id: videoPublicId || '' },
    {
      skip: !isVideoFile || shouldFetch
    },
  );

  const { showNotification } = useActions();
  const [getDocs] = useLazyGetDocsQuery();

  const getSummaryHandle = async () => {
    try {
      const summaryLink = await getDocs({
        playlistId: playlistId || '',
        videoPublicId: videoPublicId || '',
        hash,
      }).unwrap();

      const a = document.createElement('a');
      a.href = summaryLink.pdfFile;
      a.target = '_blanc';
      a.download = `${videoPublicId}.pdf`;

      a.click();
    } catch (e) {
      showNotification({ text: `Не получилось скачать конспект. Попробуйте чуть позже`, severity: 'error' });
    }
  };

  const getFileSummaryHandle = async ({
    summaryLink,
    videoPublicId,
  }: {
    summaryLink: string;
    videoPublicId: string;
  }) => {
    try {
      const a = document.createElement('a');
      a.href = summaryLink;
      a.target = '_blanc';
      a.download = `${videoPublicId}.pdf`;

      a.click();
    } catch (e) {
      showNotification({ text: `Не получилось скачать конспект. Попробуйте чуть позже`, severity: 'error' });
    }
  };

  if (isVideoFile) {
    return (
      <Wrapper>
        {isFileSummaryLoading && <FullScreenLoader />}
        {fileFullSummary?.markdown ? (
          <>
            <Button
              onClick={() =>
                getFileSummaryHandle({ summaryLink: fileFullSummary.pdfFile, videoPublicId: fileFullSummary.publicId })
              }
            >
              {t('download_summary')}
            </Button>
            <Editor
              editorState={EditorState.createWithContent(
                convertFromRaw(markdownToDraft(fileFullSummary.markdown || '')),
              )}
              readOnly
              toolbarHidden
              editorStyle={{
                border: '2px solid #E4E4FF',
                borderRadius: '10px',
                padding: '12px 16px 14px 16px',
                marginTop: '20px',
                minHeight: '300px',
                fontSize: '20px',
                fontWeight: 'lighter',
                color: '#E4E4FF',
                fontFamily: 'Nunito, sans-serif',
                lineHeight: '130%',
              }}
            />
          </>
        ) : (
          <Text>{t('load_summary')}</Text>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}
      {data && data.markdown ? (
        <>
          <Button onClick={() => getSummaryHandle()}>{t('download_summary')}</Button>
          <Editor
            editorState={EditorState.createWithContent(
              convertFromRaw(markdownToDraft(data.markdown || '')),
            )}
            readOnly
            toolbarHidden
            editorStyle={{
              border: '2px solid #E4E4FF',
              borderRadius: '10px',
              padding: '12px 16px 14px 16px',
              marginTop: '20px',
              minHeight: '300px',
              fontSize: '20px',
              fontWeight: 'lighter',
              color: '#E4E4FF',
              fontFamily: 'Nunito, sans-serif',
              lineHeight: '130%',
            }}
          />
        </>
      ) : (
        <Text>{t('load_summary')}</Text>
      )}
    </Wrapper>
  );
};
