import { SwitchItem, SwitchTitle, Wrapper } from './TabsSwitcherStyle';

import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export const TabSwitcher = memo(({ rootPath }: { rootPath: 'library' | 'account/view' }) => {
  const { t } = useTranslation('components');

  const location = useLocation();

  const switchPosition = () => {
    const pathname = location.pathname;
    if (pathname === rootPath) {
      return '0%';
    }
    if (pathname.includes('videos')) {
      return '33%';
    }
    if (pathname.includes('files')) {
      return '65%';
    }
    return '0%';
  };

  return (
    <Wrapper switchPosition={switchPosition()}>
      <Link to=".">
        <SwitchItem isActive={location.pathname === `/${rootPath}`}>
          <SwitchTitle>{t('pl_other')}</SwitchTitle>
        </SwitchItem>
      </Link>
      <Link to="./videos">
        <SwitchItem isActive={location.pathname === `/${rootPath}/videos`}>
          <SwitchTitle>{t('video', { count: 101 })}</SwitchTitle>
        </SwitchItem>
      </Link>
      <Link to="./files">
        <SwitchItem isActive={location.pathname === `/${rootPath}/files`}>
          <SwitchTitle>{t('files', { count: 101 })}</SwitchTitle>
        </SwitchItem>
      </Link>
    </Wrapper>
  );
});

TabSwitcher.displayName = 'TabSwitcher';
