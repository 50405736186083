import {getMinutes} from './helpers';
import {TimecodeDiscription} from './TimecodeDiscription';
import {
  Time,
  TimecodesWrapper,
  Title,
  TimecodesContentWrapper,
  TimecodesTitleWrapper,
  List,
  ListItem,
  Text,
  MoreButton,
  TimecodesTitle,
} from './Timecodes.styled';

import {memo, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {useGetAllShortSummaryQuery, useGetTimecodesQuery} from '@/api';
import {FullScreenLoader} from '@/components';
import {useHandlingError} from '@/hooks';

interface TimecodesProps {
  setTime: (time: number) => void;
  playlistId: string;
  id: string;
  currentTime: number | null;
  playlistType: string;
  hash?: string;
  isVideoFile?: boolean;
}

export const Timecodes = memo(
  ({setTime, playlistId, id, currentTime, playlistType, hash, isVideoFile = false}: TimecodesProps) => {
    const {t} = useTranslation('error');
    const [isCollapsed, setIsCollapsed] = useState(true);
    const {catchError} = useHandlingError();
    const shouldFetch = playlistType === 'public';

    const {data, isLoading, isError, error} = useGetTimecodesQuery(
      {playlistId, videoPublicId: id, hash},
      {skip: !playlistId || isVideoFile || shouldFetch},
    );

    const {
      data: summaries,
      isError: isSummariesError,
      isLoading: isSummariesLoading,
    } = useGetAllShortSummaryQuery({public_id: id ?? ''}, {skip: !isVideoFile || shouldFetch});

    const timings = data?.map((array) => array.start) || [];

    const highlightChapter = (i: number) => {
      // TODO: make a scroll to current timecodes
      // if (currentTime && currentTime >= timings[i] && currentTime < timings[i + 1]) {
      //   refs?.[i]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // }
      return (
        currentTime &&
        currentTime >= (timings[i] as number) &&
        (timings[i + 1] === undefined || currentTime < (timings[i + 1] as number))
      );
    };

    useEffect(() => {
      if (error) {
        catchError(error);
      }
    }, [error]);

    const onReadMoreClick = () => {
      setIsCollapsed(!isCollapsed);
    };

    if (playlistType === 'public') {
      return null;
    }

    return (
      <>
        {playlistId && !shouldFetch &&
            <TimecodesWrapper isCollapsed={isCollapsed}>
                <TimecodesContentWrapper>
                  {(isLoading || isSummariesLoading) && <FullScreenLoader/>}
                  {(isError || isSummariesError) && <Text>{t('load_timecodes', {ns: 'loading'})}</Text>}
                  {!isVideoFile && (!data || (data && data.length === 0)) && (
                    <Text>{t('load_timecodes', {ns: 'loading'})}</Text>
                  )}
                  {isVideoFile && (!summaries || (summaries && summaries.results.length === 0)) && (
                    <Text>{t('load_timecodes', {ns: 'loading'})}</Text>
                  )}

                  {/*{isError && <Text>{t('err_timecodes')} <Link to="mailto:contact@visaver.com">contact@visaver.com</Link></Text>}*/}
                  {data && data.length > 0 && (
                    <>
                      <Title>{t('timecodes', {ns: 'components'})}</Title>
                      <List>
                        {data.map(({start, text, title}, i) => (
                          <ListItem key={i}>
                            {/*  ref={refs?.[i]} */}

                            <TimecodesTitleWrapper onClick={() => setTime(start as number)}>
                              <Time>{getMinutes(start as number)}</Time>
                              <TimecodesTitle
                                style={{backgroundColor: highlightChapter(i) ? '#BE9F4B' : 'transparent'}}>
                                {title}
                              </TimecodesTitle>
                            </TimecodesTitleWrapper>
                            <TimecodeDiscription text={text}/>

                          </ListItem>
                        ))}
                      </List>
                      <MoreButton theme="inline" onClick={onReadMoreClick}>
                        {isCollapsed
                          ? t('timecodes_button', {ns: 'components'})
                          : t('timecodes_button_more', {ns: 'components'})}
                      </MoreButton>
                    </>
                  )}
                </TimecodesContentWrapper>
            </TimecodesWrapper>
        }
      </>
    );
  },
);

Timecodes.displayName = 'Timecodes';
