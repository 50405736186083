/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { getStatisticsItems } from './helpers/getStatisticsItems';
import {
  ButtonsWrapper,
  Text,
  Description,
  DescriptionImage,
  DescriptionInner,
  StatisticsList,
  StatisticsPanel,
  StatisticsPanelItem,
  StatisticsPanelItemText,
  Title,
  DescriptionMenuWrapper,
  TextWrapper,
  VideoListWrapper,
  DescriptionImageWrapper,
  StatusInfo,
} from './PlaylistPage.styled';

import { EditButtons, VideoFragmentCard } from '../../components/Card';
// import LikeButton from '../../components/Shared/Buttons/LikeButton/LikeButton';
import PlusButton from '../../components/Shared/Buttons/PlusButton/PlusButton';
import ShareButton from '../../components/Shared/Buttons/ShareButton/ShareButton';
import DropDown from '../../components/Shared/DropDown';
import DropDownMenuItem from '../../components/Shared/DropDown/DropDownMenu/DropDownIMenuItem';
import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';
import Input from '../../components/Shared/Input/Input';
import SearchInVideoInput from '../../components/Shared/SearchInVideoInput/SearchInVideoInput';
import CrossIcon from '../../components/SvgIcons/CrossIcon';
import DotsHorizontalIcon from '../../components/SvgIcons/DotsHorizontalIcon';
import EditIcon from '../../components/SvgIcons/EditIcon';
import SettingsIcon from '../../components/SvgIcons/SettingsIcon';
import TickIcon from '../../components/SvgIcons/TickIcon';
import TrashIcon from '../../components/SvgIcons/TrashIcon';
import { VideoList } from '../PlayListOutlet/components/VideoList/VideoList';
import { CardList } from '../VideoPage/VideoPage.styled';

import { type Reducer, useEffect, useReducer, useRef, useState, memo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { InDev } from '@/App.styled';
import {
  RemovePlaylist,
  Button,
  VideoModal,
  EditPlaylist,
  AddPlaylistVideo,
  SuggestVideo,
  RemoveVideo,
  SharePrivateButton,
} from '@/components';
import { LockIcon } from '@/components/SvgIcons';
import { API_SHARE_URL } from '@/constants';
import { useHandlingError, useAppSelector, useActions } from '@/hooks';
// import { VideoWithFragments } from '@/types';

export const PlaylistPage = () => {
  const { t } = useTranslation('components');
  const { showNotification, openModal } = useActions();
  const [isAuth, user_id] = useAppSelector((state) => [state.user.isAuth, state.user.user_id]);
  const [params] = useSearchParams();

  const navigate = useNavigate();

  const searchQueryValue = params.get('search');

  const [isTextCollapsed, setIsTextCollapsed] = useState(true);

  const { playlistId } = useParams();

  const { catchError } = useHandlingError();

  const {
    data: playlist,
    isLoading,
    isError,
    error: playlistError,
  } = playlistsAPI.useGetPlaylistByIdQuery({ id: playlistId ?? '' });

  const [createPrivateLink, { isLoading: isLinkLoading }] = playlistsAPI.useCreatePrivateLinkMutation();

  useEffect(() => {
    if (playlist && playlist.owner.publicId !== user_id) {
      navigate(`/playlistview/${playlist.publicId}`, { replace: true });
    }

    // if (!user_id) {
    //   navigate('/', { replace: true });
    // }
  }, [playlist, user_id]);

  const dropDownMenuItems = [
    {
      text: t('menu.set_pl'),
      icon: <SettingsIcon />,
      cb: () => openModal({ active: 'EditPlayList' }),
    },
    {
      text: t('menu.del_pl'),
      icon: <TrashIcon />,
      cb: () => openModal({ active: 'RemovePlaylist' }),
    },
  ];
  // TODO: раскомемнтировать для проверки запроса
  // const { data: allQuizzes } = useGetAllQuizzesQuery({ playlistId: playlistId ?? '' }, { skip: !playlistId });

  const [opened, setOpened] = useReducer<Reducer<boolean, boolean>>((v: boolean) => !v, false);

  const toggleHandler = () => {
    setOpened(opened);
  };

  const [getSearchVideos, { data: searchVideos, isLoading: isSearchLoading, error: searchError }] =
    playlistsAPI.useLazyGetFullSearchQuery();

  const sharedButtonHandler = async () => {
    if (playlist?.privacyType == 'public') {
      navigator.clipboard.writeText(`${API_SHARE_URL}/playlistview/${playlistId}`);
      showNotification({
        text: t('link', { ns: 'success' }),
        severity: 'success',
      });
    } else {
      try {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 30);
        const lifetime = currentDate.toISOString();

        if (playlist) {
          const data = await createPrivateLink({ publicId: playlist?.publicId, lifetime }).unwrap();
          navigator.clipboard.writeText(`${API_SHARE_URL}/private/${playlist.publicId}/${data.linkHash}`);
          showNotification({
            text: t('link', { ns: 'success' }),
            severity: 'success',
          });
        }
      } catch (e) {
        showNotification({
          text: t('link', { ns: 'error' }),
          severity: 'error',
        });
      }
    }
  };

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const textArea = textareaRef.current;

    if (isEdit && textArea) {
      textArea.focus();
      textArea.setSelectionRange(textArea.value.length, textArea.value.length);
      textArea.scrollTo(0, textArea.scrollHeight);
    }
  }, [isEdit]);

  const [updatePL, { isLoading: isUpdatePL, error: playlistUpdateError }] =
    playlistsAPI.useUpdatePartialPlaylistByIdMutation();

  useEffect(() => {
    catchError(playlistError || playlistUpdateError || searchError);
  }, [playlistError, playlistUpdateError, searchError]);

  const fetchEditHandler = async () => {
    const value = textareaRef.current?.value;
    if (value === undefined) return;
    if (playlist) {
      const result = await updatePL({
        id: playlist.publicId,
        body: { description: value },
      });

      if ('data' in result) {
        setIsEdit(false);
      }
    }
  };

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchVideos({ query, publicId: playlistId ?? '' });
    },
    [playlistId],
  );

  return (
    <>
      {(isLoading || isUpdatePL) && <FullScreenLoader />}
      {isError && <InDev />}
      {!isError && playlist && (
        <>
          <Description>
            <DescriptionImageWrapper>
              <DescriptionImage src={playlist.category.image ?? '/images/playlist_mock.png'} alt={playlist.title} />
              <StatusInfo>
                {playlist.privacyType === 'private' && <LockIcon theme="outline" />}

                {/* <UnlockIcon theme="outline" /> */}
              </StatusInfo>
            </DescriptionImageWrapper>
            <DescriptionInner>
              {/* <DescriptionContent> */}
              <Title>{playlist.title}</Title>
              <DescriptionMenuWrapper>
                <DropDown
                  items={dropDownMenuItems.map((item) => (
                    <DropDownMenuItem key={item.text} text={item.text} icon={item.icon} onClick={item.cb} />
                  ))}
                  isOpen={opened}
                  toggleOpen={toggleHandler}
                >
                  <DotsHorizontalIcon />
                </DropDown>
              </DescriptionMenuWrapper>
              {isEdit ? (
                <Input
                  ref={textareaRef}
                  type="textarea"
                  defaultValue={playlist.description}
                  rows={6}
                  maxLength={5000}
                />
              ) : (
                <TextWrapper isCollapsed={isTextCollapsed}>
                  <Text>
                    {isTextCollapsed ? playlist.description.slice(0, 127) : playlist.description.replace('\n', '<br>')}
                    {isTextCollapsed && playlist.description.length > 127 ? '... ' : ' '}
                    {playlist.description.length > 100 && (
                      <span
                        onClick={() => setIsTextCollapsed(!isTextCollapsed)}
                        style={{ cursor: 'pointer', opacity: 0.6, fontSize: 12 }}
                      >
                        {isTextCollapsed ? t('more') : t('less')}
                      </span>
                    )}
                  </Text>
                </TextWrapper>
              )}
              {/* </DescriptionContent> */}

              <EditButtons>
                <Button
                  style={{ padding: '2px' }}
                  theme="transparent"
                  variant="withIcon"
                  onClick={() => setIsEdit(!isEdit)}
                >
                  {isEdit ? <CrossIcon /> : <EditIcon width={15} height={15} />}
                </Button>
                {isEdit && (
                  <Button
                    style={{ padding: '2px' }}
                    theme="transparent"
                    variant="withIcon"
                    disabled={isUpdatePL}
                    onClick={fetchEditHandler}
                  >
                    <TickIcon width={20} height={20} />
                  </Button>
                )}
              </EditButtons>
            </DescriptionInner>
          </Description>
          {isAuth && (
            <SearchInVideoInput
              placeholder={t('search_in_pl', { ns: 'components' })}
              getSearch={getSearchVideosHandler}
            />
          )}
          <StatisticsPanel>
            <StatisticsList>
              {getStatisticsItems(playlist).map((item, index) => (
                <StatisticsPanelItem key={index}>
                  <StatisticsPanelItemText>{item.text}</StatisticsPanelItemText>
                  {item.icon}
                </StatisticsPanelItem>
              ))}
            </StatisticsList>
            <ButtonsWrapper>
              {playlist.privacyType === 'private' ? (
                <SharePrivateButton onClick={sharedButtonHandler} isLoading={isLinkLoading} content="Поделиться" />
              ) : (
                <ShareButton onClick={sharedButtonHandler} />
              )}
              {/* <LikeButton isLike={relation && relation.is_liked} /> */}
              <PlusButton onClick={() => openModal({ active: 'AddPlayListVideo' })} />
            </ButtonsWrapper>
          </StatisticsPanel>
          <VideoListWrapper>
            {isSearchLoading && <FullScreenLoader />}
            {searchVideos &&
              searchQueryValue &&
              (searchVideos.length ? (
                <CardList>
                  {searchVideos.map((fragment) =>
                    fragment.cues.map((cue, i) => (
                      <Link to={`watch/${fragment.publicId}/?t=${cue.timestampLink}`} key={fragment.publicId + i}>
                        <VideoFragmentCard fragment={cue} videoPreview={fragment.thumbnailUrl} />
                      </Link>
                    )),
                  )}
                </CardList>
              ) : (
                // <VideoList videoSettings={{ withTime: true }} videos={searchVideos} />
                <Title>{t('main.no_result', { ns: 'pages' })}</Title>
              ))}
            {!searchQueryValue && (
              <VideoList
                videos={playlist.videos}
                videoSettings={{ menu: true, isPrivate: playlist.privacyType === 'private' }}
              />
            )}
          </VideoListWrapper>

          <AddPlaylistVideo playlist={playlist} />
          <RemovePlaylist publicId={playlist.publicId} />
          <RemoveVideo />
          <SuggestVideo listAiSuggestedVideoPks={playlist.listAiSuggestedVideoPks} />
          <EditPlaylist id={playlist.publicId} />
          <VideoModal />
        </>
      )}
    </>
  );
};
export default memo(PlaylistPage);

PlaylistPage.displayName = 'PlaylistPage';
