import { Summary } from './components/Summary';
import { Timecodes } from './components/Timecodes';
import {
  ButtonTab,
  CardList,
  ContainerVideoPage, InfoBlockPlaylistMaterial,
  Inner,
  Panel,
  TabWrapper,
  TextTab,
  Title,
  VideoWrapper
} from './VideoPage.styled';

import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';
import { Quiz } from '../QuizPage/Quiz';

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { videosAPI, playlistsAPI, useGetOneVideoFilesQuery } from '@/api';
import { FileVideoPlayer, VkVideoPlayer, YouTubeVideoPlayer } from '@/components';
import { VideoFragmentCard } from '@/components/Card';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import { useAppSelector, useHandlingError, useActions, useMatchMedia } from '@/hooks';
import { InfoText} from "@/pages/AdminPage/AdminPage.styled";

const VideoPage = () => {
  const { t } = useTranslation();
  const { id, playlistId, hash } = useParams();
  const [params, setParams] = useSearchParams();
  const [currentTime, setCurrentTime] = useState(0);
  const { catchError } = useHandlingError();
  const isTablet = useMatchMedia('(max-width: 768px)');
  const [tab, setTab] = useState(1);
  const [showInfo, setShowInfo] = useState(false)

  const search = params.get('search');

  const isAuth = useAppSelector((state) => state.user.isAuth);

  const isVideoFile = useMemo(() => params.get('type') === 'videoFile', [params]);

  const { showNotification } = useActions();

  const { data: video, error: videoError } = videosAPI.useGetMovieByIdQuery(
    { id: id ?? '' },
    { skip: !isAuth || isVideoFile },
  );

  const { data: videoFile, error: videoFileError } = useGetOneVideoFilesQuery(
    { public_id: id ?? '' },
    { skip: !isAuth || !isVideoFile },
  );

  const { data: playlist } = playlistsAPI.useGetPlaylistByIdQuery(
    { id: playlistId ?? '' },
    { skip: !isAuth || !playlistId || Boolean(hash) },
  );

  const playlistType = playlist ? playlist.privacyType : 'public';
  const shouldFetch = playlistType === 'public';

  const [getSearchVideos, { data: searchVideos, isLoading: isSearchLoading, error: searchError }] =
    playlistsAPI.useLazyGetFullSearchQuery();

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchVideos({ query, publicId: playlistId || '' });
    },
    [playlistId],
  );

  useEffect(() => {
    if (!isAuth) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  useEffect(() => {
    catchError(videoError || searchError || videoFileError);
  }, [videoError, searchError, videoFileError]);

  const iframe = useRef<{ goToTime: (time: number) => void } | null>(null);
  const iframeWrapper = useRef<HTMLDivElement>(null);

  const height = isTablet ? '300px' : '500px';

  const goToTime = useCallback(
    (time: number) => {
      iframe.current?.goToTime(time);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  const startsForm = useMemo(() => {
    const time = params.get('t');
    return time ? parseInt(time) : 0;
  }, [params]);

  return (
    <ContainerVideoPage>
      {/* {(isLoading || isVideoFileIsLoading) && <FullScreenLoader />} */}
      <Inner>
        {!isVideoFile && video && (
          <>
            <div>
              <VideoWrapper ref={iframeWrapper}>
                {video.source === 'YOUTUBE' && (
                  <YouTubeVideoPlayer
                    ref={iframe}
                    id={video.videoId}
                    title={video.title}
                    startsFrom={video.startsFrom ?? startsForm}
                    height={height}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}

                {video.source === 'VK' && (
                  <VkVideoPlayer
                    ref={iframe}
                    title={video.title}
                    startsFrom={video.startsFrom ?? startsForm}
                    originLink={video.originLink}
                    height={height}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}
                {video.source === 'UPLOADED' && (
                  <FileVideoPlayer
                    ref={iframe}
                    height={height}
                    url={video.originLink}
                    startsFrom={startsForm}
                    setCurrentTime={(time: number) => setCurrentTime(time)}
                  />
                )}
              </VideoWrapper>
            </div>
            <Title>{video.title}</Title>
          </>
        )}

        {isVideoFile && videoFile && (
          <div>
            <FileVideoPlayer
              ref={iframe}
              height={height}
              url={videoFile.file}
              startsFrom={startsForm}
              setCurrentTime={(time: number) => setCurrentTime(time)}
            />
            <Title>{videoFile.file.split('/').at(-1)}</Title>
          </div>
        )}
        {isAuth && (
          <>
            <div>
              <Panel>
                <div style={{ flex: 1 }} >
                  {isAuth && <SearchInVideoInput getSearch={getSearchVideosHandler}
                                                 disabled={isVideoFile || shouldFetch && videoFile !== null || shouldFetch }
                  />}
                </div>
                <TabWrapper onMouseOver={()=>setShowInfo(true)}
                            onMouseOut={()=>setShowInfo(false)}
                            disabled={isVideoFile || shouldFetch }>
                  <ButtonTab disabled={isVideoFile || shouldFetch }
                             theme={`${tab === 1 ? 'white' : 'transparent'}`}
                             onClick={() => setTab(1)}>
                    <TextTab>{t('timecodes', { ns: 'components' })}</TextTab>
                  </ButtonTab>
                  <ButtonTab disabled={isVideoFile || shouldFetch }
                             theme={`${tab === 2 ? 'white' : 'transparent'}`}
                             onClick={() => setTab(2)}>
                    <TextTab>{t('summary', { ns: 'components' })}</TextTab>
                  </ButtonTab>
                  <ButtonTab disabled={isVideoFile || shouldFetch }
                             theme={`${tab === 3 ? 'white' : 'transparent'}`}
                             onClick={() => setTab(3)}>
                    <TextTab>{t('quiz', { ns: 'components' })}</TextTab>
                  </ButtonTab>
                </TabWrapper>
                {shouldFetch && showInfo &&
                    <InfoBlockPlaylistMaterial>
                        <InfoText>{t('info_public_pl_text', {ns: 'components'})}</InfoText>
                    </InfoBlockPlaylistMaterial>
                }
                {isVideoFile && showInfo &&
                    <InfoBlockPlaylistMaterial>
                        <InfoText>{t('info_public_pl_text', {ns: 'components'})}</InfoText>
                    </InfoBlockPlaylistMaterial>
                }
              </Panel>

              {search && searchVideos && video && (
                <>
                  {searchVideos.length === 0 && (
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <Title>{t('main.no_result', { ns: 'pages' })}</Title>
                    </div>
                  )}
                  <CardList>
                    {searchVideos.map((fragment) =>
                      fragment.cues.map((cue, i) => {
                        if (fragment.publicId === video.publicId) {
                          return (
                            <VideoFragmentCard
                              fragment={cue}
                              key={fragment.publicId + i}
                              goToTime={goToTime}
                              videoPreview={fragment.thumbnailUrl}
                            />
                          );
                        }
                      }),
                    )}
                  </CardList>
                </>
              )}
              {isSearchLoading && <FullScreenLoader />}
            </div>
            {tab === 1 && (
              <Timecodes
                playlistType={playlistType}
                playlistId={playlistId || ''}
                id={id || ''}
                hash={hash}
                setTime={goToTime}
                currentTime={currentTime}
                isVideoFile={isVideoFile}
              />
            )}
            {tab === 2 && (
              <Summary
                isVideoFile={isVideoFile}
                playlistId={playlistId || ''}
                videoPublicId={id || ''}
                hash={hash}
                playlistType={playlistType}
              />
            )}
            {tab === 3 && (
              <Quiz
                playlistId={playlistId || ''}
                videoPublicId={id || ''}
                hash={hash}
                goToTime={(time: string | number) => goToTime(time as number)}
                isVideoFile={isVideoFile}
                playlistType={playlistType}
              />
            )}
          </>
        )}
      </Inner>
    </ContainerVideoPage>
  );
};

export default memo(VideoPage);
