import styled from 'styled-components';

import {SecondaryText} from '@/styles';

interface AddVideoWrapperProps {
  videoLinkValue?: string;
  isCommercial: boolean
}

export const Form = styled.form`
    position: relative;
    width: 436px;

    @media screen and (max-width: 550px) {
        width: 100%;
    }
`;

export const Label = styled.label`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 10px;
`;

export const ButtonsWrapper = styled.a`
    display: flex;
    gap: 10px;
`;

export const AddVideoWrapper = styled.div<AddVideoWrapperProps>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border: 1px dashed #62548B;
    border-radius: 10px;
    padding: 30px;
    cursor: ${({videoLinkValue}) =>
            (videoLinkValue !== '' ? 'default' : 'pointer')};
    cursor: ${({isCommercial}) =>
            (!isCommercial ? 'default' : 'pointer')};
    opacity: ${({videoLinkValue}) => 
            (videoLinkValue !== '' ? 0.5 : '')};
    opacity: ${({ isCommercial}) => 
            (!isCommercial ? 0.5 : '')};

    &:hover {
        background-color: transparent;
    }

    ${({ videoLinkValue, isCommercial }) => videoLinkValue === '' && isCommercial && `
        &:hover {
            background-color: #3E1F86;
        }
    `}
`;
export const AddVideoText = styled.span`
    font-weight: 700;
    font-family: Nunito, sans-serif;
    font-size: 17px;
    line-height: 24px;
    color: #E4E4FF;
    width: max-content;

    @media (max-width: 1028px) {
        font-size: 15px;
    }
    @media (max-width: 480px) {
        font-size: 13px;
    }
`
export const AddVideoText1 = styled(AddVideoText)`
    text-decoration: underline;
    cursor: pointer;
`

export const SecondaryTextAddVideo = styled(SecondaryText)`
    text-align: center;
    width: max-content;
    font-size: 13px;
    line-height: 16px;
`

export const SecondaryTextErrorFormat = styled(SecondaryText)`
    font-size: 13px;
    line-height: 16px;
    color: #000046;
`

export const SecondaryTextInfoBlock = styled(SecondaryText)`
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    text-align: center;
`
export const VideoFileErrorBlock = styled.div`
    background-color: #EF7A80;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const VideoFileBlock = styled.div`
    background-color: #1F1EFE;
    border-radius: 10px;
    padding: 8px 5px;
    margin-top: 6px;
    margin-bottom: 13px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SecondaryTextAddVideoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const SecondaryTextSelect = styled(SecondaryText)`
    width: max-content;
    color: #62548B;
`

export const ErrorTextWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const TextError = styled.span`
  color: #EF7A80;
    font-size: 13px;
    font-weight: 400;
`


