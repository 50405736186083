/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  AddVideoText,
  AddVideoText1,
  AddVideoWrapper,
  ButtonsWrapper,
  ErrorTextWrapper,
  Form,
  Label,
  SecondaryTextAddVideo,
  SecondaryTextAddVideoWrapper,
  SecondaryTextErrorFormat,
  SecondaryTextInfoBlock,
  TextError,
  VideoFileBlock,
  VideoFileErrorBlock,
} from './AddVideo.styled';

import Button from '../../Buttons/Button/Button';
import FullScreenLoader from '../../FullScreenLo/FullScreenLoader';
import Input from '../../Input/Input';
import { Inner, Modal, Title } from '../Modal';

import { Link } from 'react-router-dom';
import Select from 'react-select';

import { AddVideoIcon } from '@/components/SvgIcons/AddVideoIcon';
import clearInputIcon from '@/components/SvgIcons/clearInputIcon.svg';
import DoneLoading from '@/components/SvgIcons/DoneLoading';
import iconLock from '@/components/SvgIcons/lock-icon-select.svg';
import TrashButtonModal from '@/components/SvgIcons/TrashButtonModal';
import VkIcon from '@/components/SvgIcons/VkIcon';
import YouTubeIcon from '@/components/SvgIcons/YouTubeIcon';
import { useAddVideo } from '@/hooks/useAddVideo';
import { LabelText, SecondaryText, TextCenter } from '@/styles';

export const SOURCES_DATA = [
  { link: 'https://www.youtube.com/', icon: <YouTubeIcon /> },
  // {link: 'https://www.tiktok.com/'},
  { link: 'https://vk.com/video', icon: <VkIcon /> },
  // {link: 'https://kinescope.io/'}
];

export const AddVideo = () => {
  const {
    videoLinkValue,
    selectedPlaylist,
    isLinkError,
    isError,
    selectedFile,
    isLoading,
    videoLinkHandler,
    addVideo,
    closeHandler,
    deleteVideo,
    setSelectedPlaylist,
    handleDrop,
    handleDragOver,
    onFileChange,
    handleFilePrompt,
    t,
    setHover,
    hover,
    setVideoLinkValue,
    fileInputRef,
    incorrectVideoFormat,
    result,
    stylesSelect,
    playlists,
    options,
    isCommercial,
  } = useAddVideo();

  const noPlaylistOption = [
    {
      value: 'no_playlists',
      label: 'У вас пока нет плейлистов',
      isDisabled: true,
    },
  ];

  // eslint-disable-next-line react/display-name
  const formatOptionLabel = () => (data: any) =>
    (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>{data.label}</span>
        {data.isPrivate && <img style={{ marginLeft: '10px' }} src={iconLock} alt="iconLock" />}
      </div>
    );

  const disabledSaveButton = videoLinkValue === '' && isLoading;

  const selectedPlaylistDetails = playlists?.results.find((playlist) => playlist.publicId === selectedPlaylist);

  return (
    <Modal modal={'AddVideo'} onClose={closeHandler} closeButton>
      <Title>{t('addVideo', { ns: 'header' })}</Title>
      <Inner modal={'AddVideo'}>
        <Form onSubmit={addVideo}>
          {disabledSaveButton && <FullScreenLoader />}
          <Label style={{ position: 'relative' }}>
            <Input
              style={{
                borderColor:
                  (isError && videoLinkValue !== '') || (isLinkError && videoLinkValue !== '') ? '#EF7A80' : '',
                paddingRight: '38px',
              }}
              placeholder={t('video_insert')}
              value={videoLinkValue}
              onChange={videoLinkHandler}
              disabled={selectedFile !== null}
            />
            {videoLinkValue !== '' && (
              <img
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: `${!isError && !isLinkError ? '35%' : '20%'}`,
                  right: '15px',
                }}
                onClick={() => setVideoLinkValue('')}
                src={clearInputIcon}
                alt="clearInputIcon"
              />
            )}
            {isError && videoLinkValue !== '' ? (
              <ErrorTextWrapper>
                <TextError>{t('incorrect_video_link', { ns: 'error' })}</TextError>
                <TextError>{t('incorrect_video_link1', { ns: 'error' })}</TextError>
              </ErrorTextWrapper>
            ) : null}
            {isLinkError && videoLinkValue !== '' ? (
              <ErrorTextWrapper>
                <TextError>{t('incorrect_video_link_tiktok', { ns: 'error' })}</TextError>
                <TextError>
                  {t('incorrect_video_link_tiktok1', { ns: 'error' })}
                  <Link
                    onMouseEnter={() => setHover(1)}
                    onMouseLeave={() => setHover(0)}
                    style={{ textDecoration: 'underline', color: hover === 1 ? '#62548B' : '#EF7A80' }}
                    to={'https://vk.com/video'}
                  >
                    VK,{' '}
                  </Link>
                  <Link
                    onMouseEnter={() => setHover(2)}
                    onMouseLeave={() => setHover(0)}
                    style={{
                      marginLeft: '5px',
                      marginRight: '2px',
                      textDecoration: 'underline',
                      color: hover === 2 ? '#62548B' : '#EF7A80',
                    }}
                    to={'https://www.youtube.com'}
                  >
                    YouTube{' '}
                  </Link>
                  {/*{t('incorrect_video_link_tiktok2', {ns: 'error'})}*/}
                  {/*<Link onMouseEnter={() => setHover(3)}*/}
                  {/*      onMouseLeave={() => setHover(0)}*/}
                  {/*      style={{textDecoration: "underline", color: hover === 3 ? '#62548B' : '#EF7A80'}}*/}
                  {/*      to={'https://kinescope.io'}>Kinescope</Link>*/}
                </TextError>
              </ErrorTextWrapper>
            ) : null}
          </Label>
          <TextCenter>{t('or_add_video')}</TextCenter>
          <Label>
            {selectedFile === null ? (
              <AddVideoWrapper onDrop={handleDrop}
                               onDragOver={handleDragOver}
                               videoLinkValue={videoLinkValue}
                               isCommercial={isCommercial}
              >
                <div style={{ display: 'flex', justifyContent: 'center', opacity: '0.5' }}>
                  <AddVideoIcon />
                </div>
                <div>
                  <input
                    type="file"
                    onChange={(e) => onFileChange(e)}
                    ref={fileInputRef}
                    hidden
                    accept="video/mp4" //audio/ogg
                    onClick={handleFilePrompt}
                    disabled={!isCommercial}
                  />
                  <AddVideoText1 onClick={handleFilePrompt}>{t('add_video_text1')}</AddVideoText1>
                  <AddVideoText>{t('add_video_text2')}</AddVideoText>
                </div>
                <SecondaryTextAddVideoWrapper>
                  <SecondaryTextAddVideo>{t('add_video_insert_text3')}</SecondaryTextAddVideo>
                </SecondaryTextAddVideoWrapper>
                <SecondaryTextAddVideoWrapper>
                  <SecondaryTextAddVideo>{t('add_video_insert_text1')}</SecondaryTextAddVideo>
                  <SecondaryTextAddVideo>{t('add_video_insert_text2')}</SecondaryTextAddVideo>
                </SecondaryTextAddVideoWrapper>
              </AddVideoWrapper>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ marginRight: '10px', display: 'flex', gap: '10px' }}>
                  <DoneLoading />
                  <LabelText>{selectedFile.name}</LabelText>
                </div>
                <div>
                  <TrashButtonModal onClick={deleteVideo} />
                </div>
              </div>
            )}
            {incorrectVideoFormat && (
              <VideoFileErrorBlock>
                <SecondaryTextErrorFormat>{t('video_format_invalid1')}</SecondaryTextErrorFormat>
                <SecondaryTextErrorFormat>{t('video_format_invalid2')}</SecondaryTextErrorFormat>
              </VideoFileErrorBlock>
            )}
            {selectedFile && (
              <VideoFileBlock>
                <SecondaryTextInfoBlock>{t('add_video_insert_text1')}</SecondaryTextInfoBlock>
                <SecondaryTextInfoBlock>{t('add_video_insert_text2')}</SecondaryTextInfoBlock>
              </VideoFileBlock>
            )}
          </Label>
          {selectedFile === null && (
            <Label>
              <SecondaryText style={{ color: `${!videoLinkValue ? '#62548B' : ''}`, opacity: !videoLinkValue ? '0.4' : '1'  }}>
                {t('add_video_text3')}
              </SecondaryText>

              <div style={{ position: 'relative', width: '100%' , opacity: !videoLinkValue ? '0.4' : '1' }}>
                <Select
                  options={options.length === 0 ? noPlaylistOption : options}
                  isDisabled={!videoLinkValue}
                  onChange={(selectedOption) => setSelectedPlaylist(selectedOption?.value || '')}
                  placeholder={t('video_no_select')}
                  menuPortalTarget={document.body}
                  formatOptionLabel={formatOptionLabel()}
                  styles={stylesSelect}
                />
                {videoLinkValue &&
                  !isError &&
                  !isLinkError &&
                  selectedPlaylist &&
                  selectedPlaylistDetails?.privacyType !== 'private' && (
                    <div style={{ marginTop: '5px' }}>
                      <VideoFileBlock>
                        <SecondaryTextInfoBlock>{t('video_info_pl_1')}</SecondaryTextInfoBlock>
                        <SecondaryTextInfoBlock>{t('video_info_pl_2')}</SecondaryTextInfoBlock>
                      </VideoFileBlock>
                    </div>
                  )}
              </div>
            </Label>
          )}
          <ButtonsWrapper>
            <Button disabled={disabledSaveButton} theme="white" type="submit">
              {t('form.save', { ns: 'components' })}
            </Button>
            <Button onClick={closeHandler} theme="inline">
              {t('form.cancel', { ns: 'components' })}
            </Button>
          </ButtonsWrapper>
          {result.isLoading && <FullScreenLoader />}
        </Form>
      </Inner>
    </Modal>
  );
};
