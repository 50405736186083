import { InDev } from './App.styled';
import ScrollToTop from './components/ScrollToTop';
import FullScreenLoader from './components/Shared/FullScreenLo/FullScreenLoader';
import AddMobile from './components/Shared/Modals/AddMobile/AddMobile';
import * as analytics from './ga4';
import RequireAuth from './hoc/RequireAuth';
import MainLayout from './layouts/Main/Main';
import ResultsLayout from './layouts/Search/Result/Result';
import SearchLayout from './layouts/Search/Search';
import {
  Main,
  NotFoundPage,
  Profile,
  Video,
  Policy,
  PersonalDataPolicy,
  PrivacyPolicy,
  Statistic,
  TermsOfUse,
  SearchResult,
  OnboardingLazy,
  PlayListOutlet,
  Playlist,
  PlaylistView,
  Activate,
  AdminPage,
  AdminVideoOutletLazy,
  PlaylistPrivate,
  Password,
  PlaylistsWrapper,
  VideosWrapper,
  // LibraryLazy,
  AdminPlaylists,
  AdminVideos,
  PublicVideoPageLazy,
  Library,
  FilesWrapper,
} from './pages';
import { EditQuizPage } from './pages/AdminPage/EditQuizPage';
import { EditSummaryPage } from './pages/AdminPage/EditSummaryPage';
import { EditTimecodesPage } from './pages/AdminPage/EditTimecodesPage';
import { EditTranscriptPage } from './pages/AdminPage/EditTranscriptPage';
import { PlayListPageAdmin } from './pages/AdminPage/PLayListPageAdmin/PlayListPageAdmin';
import { QuizPage as Quiz } from './pages/QuizPage';
import Global from './styles/global';

import { Suspense, useEffect } from 'react';

import { Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import {
  CreatePlaylist,
  Notification,
  ResetPassword,
  ResendEmail,
  Registration,
  Login,
  AddVideo,
  RemoveVideo,
  RemoveVideoFile,
  VerifyInfo,
} from '@/components';
import { useActions, useAppSelector } from '@/hooks';
import { ModalType } from '@/store/slices/modalsSlice';

function App() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const { openModal } = useActions();
  const [onBoarding, isAuth] = useAppSelector((state) => [state.user.onBoarding, state.user.isAuth]);
  const [params] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (!isAuth && params.has('popup')) {
      openModal({ active: params.get('popup') as ModalType });
    }
  }, [isAuth, params]);

  // console.log(location);

  const setLayout = () => (location.pathname.includes('search') ? <Outlet /> : <MainLayout />);
  // location.pathname.includes('search') === '/search' || location.pathname === '/search/results' ? <Outlet /> : <MainLayout />;

  return (
    <>
      <ScrollToTop />
      {onBoarding === null || onBoarding === 'watch' ? (
        <Routes>
          <Route
            path="/*"
            element={
              <Suspense fallback={<FullScreenLoader />}>
                <OnboardingLazy />
              </Suspense>
            }
          />
        </Routes>
      ) : (
        <Suspense fallback={<FullScreenLoader />}>
          <Routes>
            <Route path="/" element={setLayout()} errorElement={<InDev />}>
              <Route index element={<Main />} />
              {/* <Route path="/user/:id" element={<User />} /> */}
              <Route path="user/:id" element={<NotFoundPage />} />
              <Route path="playlistview/:playlistId" element={<PlayListOutlet />}>
                <Route index element={<PlaylistView />} />
                <Route path="watch/:id" element={<Video />} />
              </Route>
              <Route path="playlist/:playlistId" element={<PlayListOutlet />}>
                <Route index element={<Playlist />} />
                <Route path="watch/:id" element={<Video />} />
                <Route path="quiz">
                  <Route path=":id" element={<Quiz />} />
                </Route>
              </Route>
              <Route path="private/:playlistId/:hash" element={<PlayListOutlet />}>
                <Route index element={<PlaylistPrivate />} />
                <Route path="watch/:id" element={<Video />} />
                <Route path="quiz/:id" element={<Quiz />} />
              </Route>
              <Route path="videos" element={<PlayListOutlet />}>
                <Route path=":id" element={<Video />} />
              </Route>

              <Route path="policy" element={<Policy />}>
                <Route path="personal" element={<PersonalDataPolicy />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms_of_use" element={<TermsOfUse />} />
              </Route>
              <Route path="activate">
                <Route path=":uid">
                  <Route path=":token" element={<Activate />} />
                </Route>
              </Route>
              <Route path="password">
                <Route path="reset">
                  <Route path=":uid">
                    <Route path=":token" element={<Password />} />
                  </Route>
                </Route>
              </Route>
              {/* required pages */}
              <Route
                path="library"
                element={
                  <RequireAuth>
                    <Library />
                  </RequireAuth>
                }
              >
                <Route index element={<PlaylistsWrapper />} />
                <Route path="videos" element={<VideosWrapper />} />
                <Route path="videos/watch/:id" element={<PublicVideoPageLazy />} />
                <Route path="files" element={<FilesWrapper />} />
                <Route path="files/watch/:id" element={<Video />} />
              </Route>
              <Route
                path="profile"
                element={
                  <RequireAuth>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route index element={<Profile />} />
                <Route path="statistics" element={<Statistic />} />
              </Route>
              <Route path="search">
                <Route index element={<SearchLayout />} />
                <Route path="results" element={<ResultsLayout />}>
                  <Route index element={<SearchResult />} />
                  <Route path=":id" element={<Video />} />
                </Route>
              </Route>

              <Route
                path="account"
                element={
                  <RequireAuth>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route
                  path="view"
                  element={
                    <RequireAuth>
                      <AdminPage />
                    </RequireAuth>
                  }
                >
                  <Route index element={<AdminPlaylists />} />
                  <Route path="videos" element={<AdminVideos />} />
                  <Route path="videos/watch/:id" element={<Video />} />
                  <Route path="files" element={<FilesWrapper />} />
                  <Route path="files/watch/:id" element={<Video />} />
                </Route>
                <Route path="edit" element={<Outlet />}>
                  <Route path=":playlistId" element={<PlayListPageAdmin />}></Route>
                  <Route path=":playlistId/:videoId" element={<AdminVideoOutletLazy />}>
                    <Route path="transcript" element={<EditTranscriptPage />} />
                    <Route path="summary" element={<EditSummaryPage />} />
                    <Route path="timecodes" element={<EditTimecodesPage />} />
                    <Route path="quiz" element={<EditQuizPage />} />
                  </Route>
                </Route>
                {/* </Route> */}
              </Route>
            </Route>
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      )}
      <Suspense>
        <Login />
        <Registration />
        <CreatePlaylist />
        <AddMobile />
        <AddVideo />
        <ResetPassword />
        <ResendEmail />
        <RemoveVideo />
        <RemoveVideoFile />
        <Notification />
        <Global />
        <VerifyInfo />
      </Suspense>
    </>
  );
}
export default App;

analytics.init();
analytics.sendGTAG();
