import styled from 'styled-components';

import { theme } from '@/styles';

export const ContentWrapper = styled.div`
  display: flex;
  gap: 82px;
  position: relative;

  @media (min-width: 576px) {
    margin-top: 10vh;
    padding-left: 18vw;
    padding-right: 18vw;
    display: flex;
    gap: 82px;
  }
    
    @media (min-width: 1100px) {
    padding-left: 5vw;
    padding-right: 46vw;
  }

  @media (min-width: 1200px) {
    padding-left: 7vw;
    padding-right: 38vw;
  }

  @media (min-width: 1400px) {
    padding-left: 18vw;
  }
`;

export const TextFrame = styled.div`
    //width: 464px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 30%;

    @media (min-width: 1100px) {
        max-width: 100%;
        padding-top: 10%;
        
    }

    @media (min-width: 1200px) {
        //margin-bottom: 40px;
        padding-top: 10%;
        
    }
`;

export const HeaderImage = styled.img`
  max-width: 330px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 400px;
    margin: 0;
  }

  @media (min-width: 1100px) {
    width: auto;
  }
`;

export const TextContent = styled.p`
  font-family: ${theme.fonts.nunito};
  font-size: 25px;
  font-weight: 400;
  line-height: 130%;
  color: ${theme.colors.label.label_light};
  margin: 0 auto;

  @media (min-width: 576px) {
    margin: 0;
  }

  @media (min-width: 1100px) {
    font-size: 26px;
  }
`;

export const TabletImageWrapper = styled.div`
  width: 30vw;

  @media (min-height: 1300px) {
    width: 65vw;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 0;
  }
`;
export const TabletImage = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    width: 45%;
    position: absolute;
    //top: -33%;
  }

  @media (min-width: 1200px) {
    //width: 52%;
    //padding-right: 10vh;
  }

  @media (min-width: 1600px) {
    //top: -40%;
    //width: 815px;
    //padding-right: 0;
  }
`;

export const PositionImg = styled.img`
  display: none;

  @media (min-width: 1100px) {
    display: block;
    width: 25%;
    position: absolute;
    top: 43%;
    left: 42%;
  }

  @media (min-width: 1200px) {
    top: 63%;
    //top: 31%;
    left: 39%;
    //width: 30%;
  }

  @media (min-width: 1400px) {
    top: 67%;
    left: 45%;
  }

  @media (min-width: 1600px) {
    //width: 400px;
    top: 70%;
    left: 47%;
  }
`;
